import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (
      !this.hasExistingCoordinates() &&
      !this.hasDeniedGeolocationPermissions()
    ) {
      this.requestGeolocation();
    }
  }

  hasExistingCoordinates() {
    if (this.getCookie("_openunit_lat") && this.getCookie("_openunit_long")) {
      return true;
    } else {
      return false;
    }
  }

  hasDeniedGeolocationPermissions() {
    if (this.getCookie("_openunit_deny_geolocation")) {
      return true;
    } else {
      return false;
    }
  }

  requestGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setCookie("_openunit_lat", position.coords.latitude);
        this.setCookie("_openunit_long", position.coords.longitude);
      });
    } else {
      this.setCookie("_openunit_deny_geolocation", true, 14);
    }
  }

  getCookie(cname) {
    var cookies = ` ${document.cookie}`.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].split("=");
      if (cookie[0] == ` ${cname}`) {
        return cookie[1];
      }
    }
    return null;
  }

  setCookie(cname, cvalue, expiryDays) {
    if (expiryDays) {
      const d = new Date();
      d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = document.cookie = cname + "=" + cvalue + ";" + expires;
    } else {
      document.cookie = cname + "=" + cvalue;
    }
  }
}
