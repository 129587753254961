import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'accountHolderNameElement', 'countryElement', 'currencyElement',
    'accountNumberElement', 'routingNumberElement', 'personalNumberElement',
    'fileUploadElement', 'name', 'form', 'error', 'toggleable', 'accountHolderEmailElement'
  ]

  connect() {
    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    let stripeKey = stripeMeta.getAttribute("content")
    this.stripe   = Stripe(stripeKey)
    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get("setup-intent")

    this.toggleClass = this.data.get("class") || "hidden"
  }

  selectCountry() {
    this.toggleableTargets.forEach( target => {
      target.classList.remove(this.toggleClass)
    })
  }

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }
  }

  submit(event) {
    event.preventDefault()
    event.target.textContent = "Processing..."
    event.target.classList.add("disabled")
    event.target.disabled = true

    // // One time payments
    this.setupExternalAccount(event)
  }


  setupExternalAccount(event) {
    if (this.accountHolderNameElementTarget.value == "") {
      this.errorTarget.textContent = "The name of the individual that owns the bank account is required."
      this.reset(event)
      return
    }

    this.stripe.confirmUsBankAccountSetup(this.setup_intent, {
      payment_method: {
        us_bank_account: {
          routing_number: this.routingNumberElementTarget.value,
          account_number: this.accountNumberElementTarget.value,
          account_holder_type: 'individual',
        },
        billing_details: {
          name: this.accountHolderNameElementTarget.value,
          email: this.accountHolderEmailElementTarget.value
        },
      },
    }).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
        this.reset(event)
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method, result.setupIntent.id)
      }
    })
  }

  handlePaymentMethod(external_account_id, setup_intent_id) {
    this.addHiddenField("customer[processor]", "stripe")
    this.addHiddenField("customer[external_account_id]", external_account_id)
    this.addHiddenField("customer[setup_intent_id]", setup_intent_id)

    this.formTarget.requestSubmit()
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)
    this.formTarget.appendChild(hiddenInput)
  }

  reset(e) {
    e.target.textContent = "Connect Bank Account"
    e.target.classList.remove("disabled")
    e.target.disabled = false
  }
}
