/*jslint browser: true, indent: 2, plusplus: true, vars: true */

(function (window) {
  "use strict";

  var debugMode = false;
  var visitTtl, visitorTtl;
  var visitId, visitorId;

  var config = {
    urlPrefix: "",
    visitsUrl: "/visits",
    page: null,
    platform: "Web",
    useBeacon: true,
    startOnReady: true,
    trackVisits: true,
    cookies: true,
    cookieDomain: null,
    headers: {},
    visitParams: {},
    withCredentials: false,
    visitDuration: 4 * 60, // default 4 hours
    visitorDuration: 2 * 365 * 24 * 60 // default 2 years
  };

  if (debugMode) {
    visitTtl = 0.2;
    visitorTtl = 5; // 5 minutes
  } else {
    visitTtl = 4 * 60; // 4 hours
    visitorTtl = 2 * 365 * 24 * 60; // 2 years
  }

  function setCookie(name, value, ttl) {
    var expires = "";
    if (ttl) {
      var date = new Date();
      date.setTime(date.getTime() + (ttl * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  function getCookie(name) {
    var i, c;
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (i = 0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  function generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  function visitsUrl() {
    return config.urlPrefix + config.visitsUrl;
  }

  function page() {
    return config.page || window.location.pathname;
  }

  function debug(message) {
    window.console.log(message, visitId, visitorId);
  }

  visitId = getCookie("_openunit_visit_id");
  visitorId = getCookie("_openunit_visitor_id");

  if (visitId && visitorId) {
    // TODO keep visit alive?
    debug("Active visit");
  } else {
    if (!visitorId) {
      visitorId = generateId();
      setCookie("_openunit_visitor_id", visitorId, visitorTtl);
    }

    visitId = generateId();
    setCookie("_openunit_visit_id", visitId, visitTtl);

    if (getCookie("_openunit_visit_id")) {
      var data = {
        visit_token: visitId,
        visitor_token: visitorId,
        url: window.location.href,
        title: document.title,
        page: page()
      };

      // Set referrer
      if (document.referrer.length > 0) {
        data.referrer = document.referrer;
      }

      sendRequest(visitsUrl(), data, function () {
        // wait until successful to destroy
        // destroyCookie("ahoy_track");
        // setReady();
      });
    } else {
      debug("Cookies disabled");
    }
  }

  function csrfToken() {
    var meta = document.querySelector("meta[name=csrf-token]");
    return meta && meta.content;
  }

  function csrfParam() {
    var meta = document.querySelector("meta[name=csrf-param]");
    return meta && meta.content;
  }

  function CSRFProtection(xhr) {
    var token = csrfToken();
    if (token) { xhr.setRequestHeader("X-CSRF-Token", token); }
  }

  function sendRequest(url, data, success) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.withCredentials = config.withCredentials;
    xhr.setRequestHeader("Content-Type", "application/json");
    for (var header in config.headers) {
      if (config.headers.hasOwnProperty(header)) {
        xhr.setRequestHeader(header, config.headers[header]);
      }
    }
    xhr.onload = function() {
      if (xhr.status === 200) {
        success();
      }
    };
    CSRFProtection(xhr);
    xhr.send(JSON.stringify(data));
  }

}(window));
