import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filters", "modalCard", "modalInnerContainer"];

  isMobile() {
    return window.matchMedia("(max-width: 640px)").matches;
  }

  createModal() {
    // Cloning the filters so it can be inserted into the modal
    const filters = this.filtersTarget;

    // Creating the modal from the template
    const modal = document
      .getElementById("mobile-filter-modal")
      .content.cloneNode(true).firstElementChild;

    // Inserting the filters into the modal template
    const modalCard = modal.querySelector('[data-modal="card"]');
    this.filtersTarget.remove();

    // Adding the modal template to the DOM
    modalCard.append(filters);
    this.element.prepend(modal);

    this.element.dataset.controller = `${this.element.dataset.controller} modal`;
    this.element.dataset.modalAllowBackgroundClose = false;
    this.element.dataset.modalBackgroundHtml = " ";
  }

  initialize() {
    if (this.isMobile()) {
      this.createModal();
      this.filtersTarget.classList.remove("hidden");
    }
  }
}
