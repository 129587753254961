import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import SmoothSignature from "../libraries/signature/smooth_signature"

export default class extends Controller {
  static targets = ['input', 'form', 'field']

  connect() {
    this.wrapper = document.getElementById("signature-pad");
    this.canvas = this.wrapper.querySelector("canvas");
    this.undoButton = this.wrapper.querySelector("[data-action=undo]");
    this.clearButton = this.wrapper.querySelector("[data-action=clear]");

    this.smoothSignature = new SmoothSignature(this.canvas);

    // On mobile devices it might make more sense to listen to orientation change,
    // rather than window resize events.
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();

    this.clearButton.addEventListener("click", this.clearSig.bind(this));
  }

  onSaveFail() {
    console.log("Failed")
  }

  clearSig() {
    this.smoothSignature.clear();
  }

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);

    // This part causes the canvas to be cleared
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = "395";
    this.canvas.getContext("2d").scale(ratio, ratio);

    // This does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, smoothSignature#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data wasn't cleared. To make sure
    // that the state is consistent with visual state of the canvas, you
    // have to clear it manually.
    this.smoothSignature.clear();
  }

  submit() {
    if (this.smoothSignature.isEmpty()) {
      this.fieldTarget.value = ""
    } else {
      this.fieldTarget.value = this.smoothSignature.toDataURL()
    }
  }
}
