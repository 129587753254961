import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.element.addEventListener("click", (e) => {
      if (e.target.tagName !== "A") {
        Turbo.visit(this.urlValue)
      }
    })
  }
}
