import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.hiddenClass = this.data.get('class') || 'hidden'
  }

  close() {
    this.element.classList.add(this.hiddenClass)
  }
}
