import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["show"]

  connect() {
    if (JSON.parse(localStorage.getItem('hideBanner')) == true) {
      this.element.classList.add(this.showClass)
    }
  }

  close() {
    this.element.classList.add(this.showClass)
    localStorage.setItem('hideBanner', 'true');
  }
}
