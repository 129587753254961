import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentMethod"];
  static values = {
    paymentMethodFormUrl: String,
  };

  fetchPaymentMethodForm(event) {
    const url =
      this.paymentMethodFormUrlValue +
      "?&payment_method_id=" +
      event.target.value;

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
