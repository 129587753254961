import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "step", "input", "anchor" ]
  static values = { index: Number, display: String }
  static classes = [ "showing" ]

  indexValueChanged() {
    if (this.displayValue == "single") {
      this.showOnlyCurrentStep()
    } else {
      this.showUptoCurrentStep()
      this.scrollToCurrentStep()
    }

    this.currentInputTarget?.focus()
  }

  // Actions

  next(event) {
    this.indexValue++
  }

  reset() {
    this.indexValue = 0
  }

  // Private

  showOnlyCurrentStep() {
    this.stepTargets.forEach((target) => {
      target.classList.remove(this.showingClass)
    })

    this.currentStepTarget.classList.add(this.showingClass)
  }

  showUptoCurrentStep() {
    this.stepTargets.forEach((target, index) => {
      target.classList.toggle(this.showingClass, index <= this.indexValue)
    })
  }

  scrollToCurrentStep() {
    if (this.indexValue > 0) {
      scrollToElement(this.currentAnchorTarget)
    }
  }

  get currentStepTarget() {
    return this.stepTargets[this.indexValue]
  }

  get currentInputTarget() {
    const { currentStepTarget } = this
    return this.inputTargets.find(input => input.autofocus && currentStepTarget.contains(input))
  }

  get currentAnchorTarget() {
    return this.anchorTargets[this.indexValue]
  }
}
