import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'cardNumberElement', 'cardExpiryElement',
    'cardCvcElement', 'cardPostalCodeElement',
    'cardErrors', 'cardSuccess', 'name', 'form', 'card', 'error', 'submitPayment'
  ]

  connect() {
    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    let stripeKey = stripeMeta.getAttribute("content")
    // The payment method must be created using the platform’s publishable key
    this.stripe   = Stripe(stripeKey)
    let elements  = this.stripe.elements()

    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get("setup-intent")

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get("payment-intent")

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Setup regular payments
    this.cardNumberElement = elements.create('cardNumber', {
      showIcon: true,
      style: style
    });
    this.cardNumberElement.mount(this.cardNumberElementTarget);

    this.cardExpiryElement = elements.create('cardExpiry', {
      style: style
    });
    this.cardExpiryElement.mount(this.cardExpiryElementTarget);

    if (this.hasCardCvcElementTarget) {
      this.cardCvcElement = elements.create('cardCvc', {
        style: style
      });
      this.cardCvcElement.mount(this.cardCvcElementTarget);
    }

    if (this.hasCardPostalCodeElementTarget) {
      this.postalCodeElement = elements.create('postalCode', {
        style: style
      });
      this.postalCodeElement.mount(this.cardPostalCodeElementTarget);
    }
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }
  }

  submit(event) {
    event.preventDefault()

    event.target.textContent = "Processing..."
    event.target.classList.add("disabled")
    event.target.disabled = true
    if (this.nameTarget.value == "") {
      this.errorTarget.textContent = "Name on card is required."
      this.reset(event)
      return
    }

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.setup_intent) {
      this.setupNewCard(event)

    // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumberElement,
        billing_details: {
          name: this.nameTarget.value
        },
      }).then((result) => {
        if (result.error) {
          this.errorTarget.textContent = result.error.message
          this.reset(event)
        } else {
          this.handlePaymentMethod(result.paymentMethod.id)
        }
      })
    }
  }

  setupNewCard(event) {
    let data = {
      payment_method: {
        card: this.cardNumberElement,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }

    this.stripe.confirmCardSetup(this.setup_intent, data).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
        this.reset(event)
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method)
      }
    })
  }

  handlePaymentMethod(payment_method_id) {
    this.addHiddenField("customer[processor]", "stripe")
    this.addHiddenField("customer[card_token]", payment_method_id)

    this.formTarget.requestSubmit()
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)
    this.formTarget.appendChild(hiddenInput)
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        Turbo.clearCache()
        Turbo.visit("/")
      }
    })
  }

  reset(e) {
    e.target.textContent = "Continue"
    e.target.classList.remove("disabled")
    e.target.disabled = false
  }
}
