import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "insert",
    "links",
    "template",
    "exclusive",
    "disabledWhenExclusiveSiblingSelected",
  ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest(".nested-field")

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  set_input_boolean(event) {
    event.preventDefault()
    const wrapper = event.target.closest(".nested-field")

    if (event.target.dataset.nestedFormTarget === "exclusive") {
      const confirmed = confirm("Are you sure?")

      if (!confirmed) return false

      this.exclusiveTargets.forEach((t) => {
        const wrapper = t.closest(".nested-field")
        const input = wrapper.querySelector(`input[name*='${t.dataset.inputParam}']`)
        input.value = "false"

        t.classList.remove("disabled")
        t.textContent = t.dataset.inputTextSet
      })

      this.disabledWhenExclusiveSiblingSelectedTargets.forEach((t) => {
        t.classList.remove("disabled")
      })

      event.target.classList.add("disabled")
      wrapper.querySelector("[data-nested-form-target*='disabledWhenExclusiveSiblingSelected']").classList.add("disabled")
    }

    const inputParam = event.target.dataset.inputParam
    const input = wrapper.querySelector(`input[name*='${inputParam}']`)
    const newInputValue = (input.value === "0" || input.value === "false") ? "true" : "false" // toggle value

    if (input.value == "0" || input.value == "false") {
      event.target.textContent = event.target.dataset.inputTextUnset
    } else {
      event.target.textContent = event.target.dataset.inputTextSet
    }

    input.value = newInputValue
  }
}
