import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "field",
    "map",
    "latitude",
    "longitude",
    "address",
    "component",
    "street_address",
    "locality",
    "administrative_area_level_1",
    "postal_code",
    "country"
  ]

  static values = { latitude: Number, longitude: Number }

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    if (this.hasMapTarget) {
      let map = this.mapTarget
    } else {
      let map = document.getElementById('map')
    }

    const defaultLatitude = 39.5
    const defaultLongitude = -98.35

    this.map = new google.maps.Map(this.mapTarget, {
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      center: new google.maps.LatLng(
        this.latitudeValue || defaultLatitude,
        this.longitudeValue || defaultLongitude
      ),
      zoom: (this.latitudeValue ? 14 : 4)
    })

    if (this.hasFieldTarget) {
      this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      // Set initial restrict to the greater list of countries.
      this.autocomplete.setComponentRestrictions(
        { 'country': ['ca', 'us'] });

      this.autocomplete.bindTo('bounds', this.map)
      this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    }


    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })

    // If the data-places-latitude attribute is present,
    // add the Marker
    if (this.latitudeValue) {
      let position = {
        lat: this.latitudeValue,
        lng: this.longitudeValue
      }

      this.marker.setPosition(position)
    }
  }

  placeChanged() {
    let componentForm = {
      street_address: 'long_name',
      country: 'short_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name'
    }

    let place = this.autocomplete.getPlace()

    let address = [
      (place.address_components[0] &&
        place.address_components[0].short_name) ||
      "",
      (place.address_components[1] &&
        place.address_components[1].short_name) ||
      "",
      (place.address_components[2] &&
        place.address_components[2].short_name) ||
      "",
    ].join(" ");

    if (this.hasAddressTarget) {
      this.addressTarget.innerHTML = address
    }
    for (const component of this.componentTargets) {
      component.value = ''
      component.disabled = false
    }

    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0]
      if (componentForm[addressType]) {
        let val = place.address_components[i][componentForm[addressType]]
        this[`${addressType}Target`].value = val
      }
    }

    this.street_addressTarget.value =
      place.address_components[0]['long_name'] + ' ' +
      place.address_components[1]['long_name'];

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(14)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    if (this.hasLatitudeTarget && this.hasLongitudeTarget) {
      this.latitudeTarget.value = place.geometry.location.lat()
      this.longitudeTarget.value = place.geometry.location.lng()
    }
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
