import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu']
  static values = {
    preventClose: Boolean
  }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'

    document.addEventListener("click", (e) => {
      if (!this.hasPreventCloseValue) {
        this.hide(e)
      }
    })
  }

  toggle(e) {
    e.preventDefault()

    this.menuTarget.classList.toggle(this.toggleClass)
    if (this.element.getAttribute("aria-expanded") === 'false') {
      this.element.setAttribute("aria-expanded", true)
    } else {
      this.element.setAttribute("aria-expanded", false)
    }
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass)

      this.element.setAttribute("aria-expanded", false)
    }
  }

  navigateAway() {
    if (!this.menuTarget.classList.contains(this.toggleClass)) {
      this.menuTarget.classList.add(this.toggleClass)

      this.element.setAttribute("aria-expanded", false)
    }
  }
}
